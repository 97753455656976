<template>
  <div
    :class="$customSectionClasses(cfgs)"
    class="container"
  >
    <div
      :style="[...getColWidth]"
      class="cms__grid-block cms-block-stub"
    >
      <template
        v-for="(slotName, idx) in gridSlots"
        :key="slotName"
      >
        <div :class="[slotName]">
          <slot :name="slotName" />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">

import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { SectionTypes } from '~/plugins/customSectionClasses';

type IGridBlockProps = Omit<IBlockDefaultProps, 'values' | 'cfgs'| 'extras'> & {
	values: {
		gap:number;
		width:number;
	};
	cfgs: SectionTypes;
	extras:{
		leftWidth:number;
		rightWidth:number;
		totalCols:number;
	}
};
const props = defineProps<IGridBlockProps>();
const $slots = useSlots();
const gridSlots = computed((): string[] => Object.keys($slots));
const { $customSectionClasses } = useNuxtApp();

const getColWidth = computed(() => {
  const columnAmount = props.extras.totalCols || 12;
  const leftCol = props.extras.leftWidth || 50;
  const rightCol = props.extras.rightWidth || 50;
  const colGap = props.values.gap || 12;
  return [
			`--two-columns-left:${leftCol / columnAmount * 100}%`,
	  `--two-columns-right:${rightCol / columnAmount * 100}%`,
	    `--two-columns-gap:${colGap * 4}px`
  ];
});
</script>

<style lang="scss">
@import "@/assets/scss/media";

.cms__grid-block {
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	gap: var(--two-columns-gap,12px);

	&.cms-block-stub{
		margin: 0;
	}

	& .left{
		width: calc(var(--two-columns-left,50%) - var(--two-columns-gap,12px));
	}

	& .right{
		width: var(--two-columns-right, 50%);
	}
}

@include media('md') {
	.cms__grid-block{
		& .left{
			width:100%;
		}

		& .right{
			width: 100%;
		}
	}
}
</style>
